import $request from "@/assets/api/api";
import store from "@/store";
import { ElMessage } from "element-plus";

// 下拉生产模式选项
const Usable = (data: object, rollback: any, TypeData: any) => {
  $request.commonPost(
    "production",
    "ModelUsable",
    data,
    (res: any) => {
      console.log(res);
      if (res.code == 0) {
        // itemCode
        let list = res.data.map((item: any, i: number) => {
          return {
            id: item.id,
            name: item.modelName,
            usableStatus: item.usableStatus,
            typeName: item.usableStatus == 0 ? "已禁用" : "可用",
            color: item.usableStatus == 0 ? "red" : "green",
            sendGroupList: item.sendGroupList,
            bgc: i == 0 ? true : false,
          };
        });
        console.log("sssssssssss", TypeData.pathShow);
        if (list.length && !TypeData.pathShow) {
          store.commit("SET_modelId", list[0].id);
        }
        rollback(list);
      }
    },
    (err: any) => {
      console.log(err);
      ElMessage.error(err.msg);
    }
  );
};
export { Usable };
