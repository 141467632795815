const getClassName = (type: any) => {
  let name;
  switch (type) {
    case "停止":
      name = "gray";
      break;
    case "停机":
      name = "gray";
      break;
    case "异常":
      name = "reds";
      break;
    case "急停":
      name = "reds";
      break;
    case "手动模式":
      name = "yellow";
      break;
    case "暂停中":
      name = "yellow";
      break;
    case "示教中":
      name = "yellow";
      break;

    case "静止中":
      name = "success";
      break;
    case "步进中》》":
      name = "success";
      break;
    case "运行中":
      name = "success";
      break;

    default:
      name = "success";
  }
  return name;
};
export default getClassName;
