
import getClassName from "@/utils/getClassName";
import { de } from "element-plus/es/locale";
import { forEach } from "lodash";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  nextTick,
} from "vue";
export default defineComponent({
  name: "",
  props: {
    AllList: {
      type: Array,
    },
  },
  setup(props) {
    const data = reactive({
      machineList: [
        {
          id: 1,
          name: "机器人R1:",
          typeName: "取件中",
          color: "#01FD66",
          listCircle: 4,
          CircleColor: "skyblue",
          img: require("@/assets/img/RobotArm.png"),
        },
        {
          id: 2,
          name: "加热炉:",
          typeName: "步进中",
          color: "#01FD66",
          listCircle: 5,
          conveyorList: 10,
          CircleColor: "skyblue",
        },
        {
          id: 3,
          name: "机器人R2:",
          typeName: "空闲中",
          color: "#01FD66",
          listCircle: 4,
          CircleColor: "#fff",
          img: require("@/assets/img/RobotArm.png"),
        },
        {
          id: 4,
          name: "淬火机:",
          typeName: "淬火中",
          name2: "箱门关闭",
          color: "#01FD66",
          listCircle: 4,
          CircleColor: "skyblue",
          img: require("@/assets/img/WaterDrop.png"),
        },
        {
          id: 5,
          name: "机器人R3:",
          typeName: "空闲中",
          color: "#01FD66",
          listCircle: 4,
          CircleColor: "#fff",
          img: require("@/assets/img/RobotArm.png"),
        },
        {
          id: 6,
          name: "抛丸喷油:",
          typeName: "运行中",
          color: "#01FD66",
          listCircle: 4,
          CircleColor: "#fff",
          img: require("@/assets/img/tool.png"),
        },
        {
          id: 7,
          name: "粗定位区:",
          typeName: "工件到达",
          color: "#01FD66",
          listCircle: 2,
          CircleColor: "#fff",
          img: require("@/assets/img/location.png"),
          img2: require("@/assets/img/location.png"),
        },
        {
          id: 8,
          name: "机器人R4:",
          typeName: "空闲中",
          color: "#01FD66",
          listCircle: 4,
          CircleColor: "#fff",
          img: require("@/assets/img/RobotArm.png"),
        },
      ],
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
      getList();
    });
    const getList = () => {
      //将数据过滤添加
      let AllList = props.AllList;
      let conveyorList = <any>[];

      if (AllList?.length) {
        data.machineList = [];
        let typeData = <any>{};
        let icon = "icon-jiqiren";
        AllList.forEach((item: any) => {
          let { metaDataItemId } = item;

          // 加热炉圆点数据
          if (
            metaDataItemId == 1000017105 ||
            metaDataItemId == 1000017106 ||
            metaDataItemId == 1000017107 ||
            metaDataItemId == 1000017108 ||
            metaDataItemId == 1000017109 ||
            metaDataItemId == 1000017110 ||
            metaDataItemId == 1000017111 ||
            metaDataItemId == 1000017112 ||
            metaDataItemId == 1000017113 ||
            metaDataItemId == 1000017114 ||
            metaDataItemId == 1000017115 ||
            metaDataItemId == 1000017116 ||
            metaDataItemId == 1000017117 ||
            metaDataItemId == 1000017118 ||
            metaDataItemId == 1000017119 ||
            metaDataItemId == 1000017120 ||
            metaDataItemId == 1000017121 ||
            metaDataItemId == 1000017122
          ) {
            item.value = item.value == 0 ? "" : +item.value;

            conveyorList.push(item);
          }

          if (metaDataItemId == 1000017101) {
            // 机器人R1状态
            typeData.icon = icon;
            typeData.conveyorList = "";
            PublicContent(data.machineList, item, typeData);
          }
          if (metaDataItemId == 1000017103) {
            //加热炉状态
            typeData.conveyorList = 10;
            typeData.icon = "";
            PublicContent(data.machineList, item, typeData);
          }
          if (metaDataItemId == 1000017123) {
            //机器人R2状态
            typeData.icon = icon;
            PublicContent(data.machineList, item, typeData);
          }
          if (metaDataItemId == 1000017125) {
            //淬火机
            typeData.icon = "icon-lengqueta";
            // typeData.name2 = "箱门关闭中";
            PublicContent(data.machineList, item, typeData);
          }
          typeData.name2 = "";
          if (metaDataItemId == 1000017127) {
            //机器人R3状态
            typeData.icon = icon;
            PublicContent(data.machineList, item, typeData);
          }
          if (metaDataItemId == 1000017129) {
            //抛丸喷油状态
            typeData.icon = "icon-jiguangqiegeji";
            typeData.icon2 = "";
            PublicContent(data.machineList, item, typeData);
          }
          if (metaDataItemId == 1000017131) {
            //粗定位状态
            typeData.icon = "icon-quyu";
            typeData.icon2 = "icon-quyu";
            PublicContent(data.machineList, item, typeData);
          }
          if (metaDataItemId == 1000017133) {
            //机器人R4状态
            typeData.icon = icon;
            typeData.icon2 = "";
            PublicContent(data.machineList, item, typeData);
          }
        });
        data.machineList.forEach((item) => {
          if (item.id == 1000017103) {
            item.conveyorList = conveyorList;
          }
        });

        AllList.forEach((item: any) => {
          let { metaDataItemId } = item;
          if (metaDataItemId == 1000017102) {
            // R1工件数量
            getAddTo(item, 1000017101);
          }
          if (metaDataItemId == 1000017103) {
            //加热炉状态
          }
          if (metaDataItemId == 1000017124) {
            //R2工件数量
            getAddTo(item, 1000017123);
          }
          if (metaDataItemId == 1000017126) {
            //淬火机工件数量
            getAddTo(item, 1000017125);
          }
          if (metaDataItemId == 1000017126) {
            //R3工件数量
            getAddTo(item, 1000017127);
          }
          if (metaDataItemId == 1000017128) {
            //抛丸喷油状态
            // getAddTo(item, 1000017127);
          }
          if (metaDataItemId == 1000017132) {
            //粗定位工件数量
            getAddTo(item, 1000017131);
          }
          if (metaDataItemId == 1000017134) {
            //机器人R4工件数量
            getAddTo(item, 1000017133);
          }
        });

        console.log(data.machineList);
      }
    };
    // 统一样式封装获取
    const PublicContent = (machineList: any, Item: any, typeData: any) => {
      machineList.push({
        id: Item.metaDataItemId,
        name: Item.title,
        name2: typeData?.name2,
        typeName: Item.value,
        color: "#01FD66",
        listCircle: 2,
        CircleColor: "skyblue",
        icon: typeData.icon,
        icon2: typeData.icon2,
        conveyorList: typeData.conveyorList,
      });
    };
    // 将数量追加到数组中
    const getAddTo = (Data: any, id: any) => {
      data.machineList.forEach((item: any) => {
        if (item.id == id) {
          item.listCircle = Data.value == 0 ? "" : Data.value;
        }
      });
    };

    const getStyleName = (type: any) => {
      let color;
      switch (type) {
        case "停止":
          color = "gray";
          break;
        case "静止中":
          color = "#01fd66";
          break;
        case "步进中》》":
          color = "#01fd66";
          break;
        case "步进中》》":
          color = "yellow";
          break;
        case "异常":
          color = "red";
          break;
        case "急停":
          color = "red";
          break;

        default:
          color = "success";
      }
      return color;
    };

    return {
      ...toRefs(data),
      getClassName,
      getStyleName,
    };
  },
});
