
import bus from "@/utils/bus";
import { fa } from "element-plus/es/locale";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  onUnmounted,
} from "vue";
export default defineComponent({
  name: "",
  props: {
    Show: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const data = reactive({
      centerDialogVisible: false,
      title: "",
      widths: "22%",
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
    });
    onUnmounted(() => {});
    const getShow = (item: any, Type: any) => {
      data.centerDialogVisible = true;
      data.title = item.name;
      if (item.widths) {
        data.widths = item.widths;
      }
    };
    const getConfirm = () => {
      context.emit("getConfirm");
    };
    const getClose = (e: any) => {
      // console.log(e);
      if (e == "close") {
        data.centerDialogVisible = false;
        return;
      }
      data.centerDialogVisible = false;
      context.emit("getClose");
    };
    return {
      ...toRefs(data),
      getShow,
      getClose,
      getConfirm,
    };
  },
});
