
import store from "@/store";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  onUnmounted,
  watch,
  computed,
} from "vue";

import { useStore } from "vuex";
export default defineComponent({
  name: "",
  props: {
    addTo: {
      type: String,
      default: "",
    },
    Cut: {
      type: String,
      default: "",
    },
    typeData: {
      type: Object,
    },
    optionsList: {
      type: Array,
    },
  },
  setup(props, context) {
    const data = reactive({
      value: "",
      options: [
        {
          label: "测试数据",
          value: "测试数据",
        },
      ],
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
      console.log(props);
    });
    onUnmounted(() => {});

    const getCut = (e: any) => {
      console.log(e);
      context.emit("getOption", e);
    };
    const getValue = () => {
      data.value = "";
    };

    return {
      ...toRefs(data),
      getCut,
      getValue,
    };
  },
});
