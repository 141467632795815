import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f44173ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row al-c ju-s" }
const _hoisted_2 = { class: "row al-c machine_box" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "quench_box" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 0,
  class: "row al-c"
}
const _hoisted_9 = { class: "conveyor" }
const _hoisted_10 = { class: "conveyor" }
const _hoisted_11 = { class: "conveyor" }
const _hoisted_12 = {
  key: 1,
  class: "iconfont icon-map-direction-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.machineList, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.id
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", null, _toDisplayString(item.name) + ":", 1),
            _createElementVNode("p", {
              class: _normalizeClass(_ctx.getClassName(item.typeName))
            }, _toDisplayString(item.typeName), 3)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("i", {
                  class: _normalizeClass(["iconfont", [item.icon, _ctx.getClassName(item.typeName)]]),
                  style: {"color":"#01fd66"}
                }, null, 2),
                _createElementVNode("i", {
                  class: _normalizeClass(["iconfont", [item.icon2, _ctx.getClassName(item.typeName)]]),
                  style: {"color":"#01fd66"}
                }, null, 2),
                _createElementVNode("div", _hoisted_6, [
                  (item.id == 1000017125)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(+item.listCircle, (it) => {
                        return (_openBlock(), _createElementBlock("p", {
                          key: it,
                          class: "Circle",
                          style: _normalizeStyle({ background: item.CircleColor })
                        }, null, 4))
                      }), 128))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("p", {
                style: _normalizeStyle({ color: item.color })
              }, _toDisplayString(item.name2), 5),
              (
                item.id != 1000017103 &&
                item.id != 1000017125 &&
                item.id != 1000017129
              )
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(+item.listCircle, (it) => {
                      return (_openBlock(), _createElementBlock("p", {
                        key: it,
                        class: "Circle",
                        style: _normalizeStyle({ background: item.CircleColor })
                      }, null, 4))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            (item.id == 1000017103)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.conveyorList[0].value, (it) => {
                      return (_openBlock(), _createElementBlock("p", {
                        key: it,
                        class: "Circle",
                        style: _normalizeStyle({ background: item.CircleColor })
                      }, null, 4))
                    }), 128))
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.conveyorList?.length - 2, (n, i) => {
                    return (_openBlock(), _createElementBlock("div", { key: n }, [
                      _createElementVNode("div", {
                        style: _normalizeStyle({ background: _ctx.getStyleName(item.typeName) }),
                        class: "top_box"
                      }, null, 4),
                      _createElementVNode("div", _hoisted_10, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.conveyorList[i + 1].value, (it) => {
                          return (_openBlock(), _createElementBlock("p", {
                            key: it,
                            class: "Circle",
                            style: _normalizeStyle({ background: item.CircleColor })
                          }, null, 4))
                        }), 128))
                      ]),
                      _createElementVNode("div", {
                        style: _normalizeStyle({ background: _ctx.getStyleName(item.typeName) }),
                        class: "btn_box"
                      }, null, 4)
                    ]))
                  }), 128)),
                  _createElementVNode("div", _hoisted_11, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.conveyorList[item.conveyorList?.length - 1]
                  .value, (it) => {
                      return (_openBlock(), _createElementBlock("p", {
                        key: it,
                        class: "Circle",
                        style: _normalizeStyle({ background: item.CircleColor })
                      }, null, 4))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true),
            (_ctx.machineList.length - 1 != index)
              ? (_openBlock(), _createElementBlock("i", _hoisted_12))
              : _createCommentVNode("", true)
          ])
        ]))
      }), 128))
    ])
  ]))
}