
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  watch,
} from "vue";
export default defineComponent({
  name: "",
  props: {
    tableObj: {
      type: Object,
    },
  },
  setup(props) {
    const data = reactive({
      centerDialogVisible: false,
      tabPosition: "All",
      styles: {
        background: "red",
      },
      tableData: [],
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
      getList(props);
    });
    watch(
      props,
      (newValue) => {
        getList(newValue);
      },
      { deep: true }
    );
    const getList = (Data: any) => {
      data.tableData = Data.tableObj?.alertInfoList;
    };
    const getCut = (e: any) => {
      console.log(e);
      if (e == "All") data.tableData = props.tableObj?.alertInfoList;
      props.tableObj?.collectByComponentName.forEach((item: any) => {
        if (item.componentName == e) {
          data.tableData = item.collectByComponentName;
        }
      });
    };
    return {
      ...toRefs(data),
      getCut,
    };
  },
});
