<template>
  <div>
    <el-table :data="rightsDate" border stripe @row-click="row_click">
      <el-table-column
        show-overflow-tooltip
        v-for="(info, i) in rightHeader"
        :key="i"
        :property="info.value"
        :label="info.label"
        align="center"
      >
        <template #default="scope">
          {{ scope.row[scope.column.property] }}
          <!-- 渲染对应表格里面的内容 -->
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        :label="tltleName"
        width="200"
        fixed="right"
        v-if="caozuo"
      >
        <template #default="scope">
          <div class="row flex ju-c">
            <span
              v-for="(item, index) in operateList"
              :key="index"
              :style="{ color: item.color }"
              class="text-blue cursor-pointer margin-right hand"
              @click="editHandle(scope.row, item.callout)"
              >{{ item.name }}</span
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    Arr: Array, // 表格数据
    Title: Array, // 头部数据
    operateList: Array,
    tltleName: String,
    caozuo: Boolean, // 操作
  },
  data() {
    return {
      rightHeader: [{ label: "", value: "" }],
      rightsDate: [],
    };
  },
  // 监听父组件数据的变化
  watch: {
    Arr: {
      handler() {
        this.processingData();
      },
      immediate: true,
      deep: true,
    },
    //
  },
  created() {},
  methods: {
    processingData() {
      this.rightHeader = this.Title;
      this.rightsDate = this.Arr;
    },
    // 点击行事件
    row_click(e) {
      this.$emit("row-click", e);
    },
    editHandle(row, type) {
      this.$emit("AllType", row, type);
    },
  },
};
</script>

<style scoped>
div,
p {
  color: #fff;
}
::v-deep .el-table,
::v-deep .el-table__expanded-cell {
  background-color: transparent !important;
}
/* 表格内背景颜色 */
::v-deep .el-table th,
::v-deep .el-table tr,
::v-deep .el-table td {
  /* // background-color: transparent !important; */
  background-color: #062b68 !important;
}
::v-deep .el-table .cell {
  color: #fff;
}
</style>
