
import { useRouter } from "vue-router";
import { Usable } from "@/assets/api/production/public";

import { ElMessage } from "element-plus";
import Api from "@/assets/api/api";
import { useStore } from "vuex";
import getClassName from "@/utils/getClassName";

import MyDialog from "@/components/Dialog/index.vue";
import MySelect from "@/components/MySelect/index.vue";
import PublicSelect from "@/components/PublicSelect/index.vue";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  ref,
  onUnmounted,
  watch,
  computed,
  nextTick,
} from "vue";
import bus from "@/utils/bus";
export default defineComponent({
  name: "",
  components: { MyDialog, MySelect, PublicSelect },
  props: {
    AllList: {
      type: Array,
    },
    type: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const store = useStore();
    const data = reactive({
      typeData: {
        pathShow: true,
      },

      ShowId: "",
      addTo: "addTo",
      Cut: "model",
      typeName: "工件类型：",
      Time: <any>"",
      DialogShow: true,
      pattern: <any>{
        modelId: "",
        produceItemCode: 1,
        projectId: store.state.projectId,
      },
      argumentsData: {
        projectId: store.state.projectId,
        produceItemCode: store.state.itemCode,
        findOnlyUsable: 0,
      },
      optionsList: <any>[],
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
    });

    let ItemCode = computed(() => {
      return store.state.Production.ProductionItemCode;
    });
    watch(
      ItemCode,
      (newValue) => {
        data.pattern.produceItemCode = newValue;
        data.argumentsData.produceItemCode = newValue;
      },
      { deep: true }
    );

    const MyMyDialog = ref();
    const PublicSelect = ref();
    const MySelect = ref();
    const getCut = (Data: any) => {
      data.ShowId == Data.metaDataItemId;
      if (Data.metaDataItemId == 1000011102) {
        data.Cut = Data.metaDataItemId == 1000011102 ? "model" : "";
        data.DialogShow = true; //重新加载组件
        data.typeName =
          Data.metaDataItemId == 1000011102 ? "工件类型:" : "工件模式";
        let Item = {
          name: "请选择更换的工件类型与模式",
        };
        setTimeout(() => {
          MyMyDialog.value.getShow(Item);
        }, 100);
      }
    };

    // 工件模式
    const getModel = () => {
      Usable(data.argumentsData, usableBack, {});
    };

    const usableBack = (Data: any) => {
      data.optionsList = Data.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      if (Data.length <= 0) {
        PublicSelect.value.getValue();
      }
    };

    const Jobmode = () => {
      nextTick(() => {
        getModel();
      });
    };

    const getOption = (e: any) => {
      data.pattern.modelId = e;
    };

    const getConfirm = () => {
      if (
        data.pattern.produceItemCode == "" &&
        data.pattern.produceItemCode != 0
      ) {
        ElMessage.error("请选择工件类型", data.pattern.produceItemCode);
        return;
      }
      if (!data.pattern.modelId && data.pattern.produceItemCode != 0) {
        ElMessage.error("请选择工件模式");
        return;
      }

      if (data.Cut != "model") {
        data.pattern.modelId = "";
      }
      // 当选中为无工件的时候把模式去掉
      if (data.pattern.produceItemCode == 0) {
        data.pattern.modelId = 0;
      }
      console.log(data.pattern);
      Api.commonPost(
        "production",
        "CutPattern",
        data.pattern,
        (res: any) => {
          console.log(res);
          if (res.code == 0) {
            ElMessage({
              message: res.msg,
              type: "success",
            });
            empty();
            context.emit("getbyPage");
            MyMyDialog.value.getClose("close");
          }
        },
        (err: any) => {
          ElMessage.error(err.msg);
        }
      );
    };
    const getClose = () => {
      data.DialogShow = false;
      empty();
    };

    // 清空
    const empty = () => {
      data.pattern.modelId = "";
      data.pattern.produceItemCode = "";
      store.commit("SET_ProductionItemCode", "");
      PublicSelect.value.getValue();
      MySelect.value.getValue();
    };

    const getSize = (i: any) => {
      let Show = false;
      if (i == 0) {
        Show = true;
      }
      return Show;
    };

    return {
      ...toRefs(data),
      getCut,
      getConfirm,
      MyMyDialog,
      getClose,
      getClassName,
      getOption,
      Jobmode,
      PublicSelect,
      MySelect,
      empty,
      getSize,
    };
  },
});
