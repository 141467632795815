import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2fd9e0f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["element-loading-spinner"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_loading = _resolveDirective("loading")!

  return (_ctx.loading)
    ? _withDirectives((_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "loading_box",
        "element-loading-text": "数据资源加载中...",
        "element-loading-spinner": _ctx.svg,
        "element-loading-svg-view-box": "-10, -10, 50, 50",
        "element-loading-background": "rgba(122, 122, 122, 0.3)"
      }, null, 8, _hoisted_1)), [
        [_directive_loading, _ctx.loading]
      ])
    : _createCommentVNode("", true)
}