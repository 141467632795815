
import Loading from "@/components/Loading/index.vue";
import getClassName from "@/utils/getClassName";
import { ElMessage } from "element-plus";
import Api from "@/assets/api/api";
import { useStore } from "vuex";

import { overallData } from "@/utils/simulation";

import PublicList from "@/components/PublicList/index.vue";
import Operate from "@/components/Operate/index.vue";
import Btnbox from "./components/Btnbox.vue";
import Dialog from "@/components/Dialog/index.vue";
import MyTable from "@/components/MyTable/index.vue";
import * as echarts from "echarts";
import { Line, Yield, Motor } from "@/assets/echarts";

import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  ref,
  nextTick,
} from "vue";
export default defineComponent({
  name: "",
  components: { PublicList, Btnbox, Dialog, MyTable, Operate, Loading },
  setup() {
    const store = useStore();
    const data = reactive({
      Title: [
        { label: "设备", value: "componentName" },
        { label: "告警内容", value: "alertInfo" },
      ],
      Arr: <any>[],
      systemList: [
        { id: 1, title: "系统状态:", value: "运行中", color: "green" },
        { id: 2, title: "工件类型:", value: " A工件" },
        { id: 3, title: "产线模式:", value: "正常模式", btnName: "切换模式" },
        { id: 4, title: "当前效率:", value: "500 件/小时" },
        { id: 5, title: "当前班次:", value: "张三三   李四四  王五五" },
      ],
      HeatingFurnace: [
        { id: 1, title: "设备 -  加热炉:", value: "步进中", color: "green" },
        { id: 2, title: "网带步进间隔:", value: " 15秒" },
        { id: 3, title: "网带步进速度:", value: "0.22m/s" },
        { id: 4, title: "入口实时温度:", value: "220°C" },
        { id: 5, title: "出口实时速度:", value: "955°C" },
        { id: 6, title: "网带偏差均值:", value: "+5 mm" },
      ],
      hardening: [
        { id: 1, name: "设备 -  淬火:", typeName: "运行中", color: "green" },
        {
          id: 2,
          name: "淬火时间设定:",
          value: "  5秒",
          pressureName: "水泵压力:",
          pressureType: "0    Mpa",
        },
        {
          id: 3,
          name: "流量监视:",
          value: "0 L/S",
          pressureName: "溢流阀压力:",
          pressureType: "2    Mpa",
        },
        {
          id: 4,
          name: "入口实时温度:",
          bgc: "#01FD66",
          normal: "正常",
          bgc2: "#fff",
          normal2: "低水位",
        },
        {
          id: 5,
          name: "设备水位状态:",
          bgc: "#01FD66",
          normal: "正常",
          bgc2: "#fff",
          normal2: "低水位",
        },
        {
          id: 6,
          name: "设备底座水位:",
          bgc: "#fff",
          normal: "正常",
          bgc2: "red",
          normal2: "低水位",
        },
      ],
      waterList: [
        { id: 1, title: "除尘器水位", bgc: "red" },
        { id: 2, title: "补油桶水位", bgc: "skyblue" },
        { id: 3, title: "喷油桶1水位", bgc: "skyblue" },
        { id: 4, title: "喷油桶2水位", bgc: "#FFFF00" },
      ],
      lineData: <any>{},
      YieldData: <any>{},
      MotorData: <any>{},
      Automation: {
        pageId: "100001",
        projectId: store.state.projectId,
      },
      pageTabs: <any>[],
      ShotBlasting: <any>[{}],
      StateFlow: [],
      Time: <any>null, // 强制更新子组件
      tableObj: <any>{},
    });
    onBeforeMount(() => {});
    onMounted(() => {
      data.lineData = Line().option;
      data.YieldData = Yield().option;
      data.MotorData = Motor().option;

      // 获取自动化监控数据
      // setTimeout(() => {
      getbyPage();
      LnfoAlert();
      // }, 500);
    });
    const getbyPage = () => {
      let res = overallData;

      console.log("SSSSSSSSSSSSSSSSSSSSS", overallData);
      // Api.commonPost(
      //   "dap",
      //   "byPage",
      //   data.Automation,
      //   (res: any) => {
      //     let {
      //       data: { pageTabs },
      //     } = res;
      data.pageTabs = res.data.pageTabs;
      data.pageTabs.forEach((item: any) => {
        // 系统区域
        if (item.id == "1000011") {
          item.dataValues.forEach((it: any) => {
            if (it.groupInfo.id == "10000111") {
              data.systemList = it.dataItemValues;

              data.systemList.forEach((n: any) =>
                n.metaDataItemId == 1000011102 ? (n.btnName = "切换模式") : ""
              );
            }
          });
        }
        // 产量

        if (item.id == "1000015") {
          item.dataValues.forEach((it: any) => {
            if (it.groupInfo.id == "10000151") {
              // 产品区域
              data.YieldData.series[0].data = [it.dataItemValues[0]?.value];
              data.YieldData.series[1].data = [it.dataItemValues[1]?.value];
            }
          });
        }

        // 加热炉
        if (item.id == "1000012") {
          item.dataValues.forEach((it: any) => {
            if (it.groupInfo.id == "10000121") {
              // 状态区域
              data.HeatingFurnace = it.dataItemValues;
            }
            if (it.groupInfo.id == "10000122") {
              //加热炉温度图
              data.lineData.series[0].data = [];
              data.lineData.xAxis.data = [];
              it.dataItemValues.forEach((item: any) => {
                data.lineData.xAxis.data.push(item.title);
                data.lineData.series[0].data.push(item.value);
              });
            }
            if (it.groupInfo.id == "10000123") {
              //加热炉 运行温度图
              data.lineData.series[1].data = [];
              it.dataItemValues.forEach((item: any) => {
                data.lineData.series[1].data.push(item.value);
              });
            }
          });
        }

        // 淬火区域
        if (item.id == "1000013") {
          // 状态区域
          item.dataValues.forEach((it: any) => {
            if (it.groupInfo.id == "10000131") {
              it.dataItemValues[0].widths = 0;

              data.hardening = <any>it.dataItemValues;
            }

            if (it.groupInfo.id == "10000132") {
              let hardening2 = JSON.parse(JSON.stringify(data.hardening));
              let hardening = it.dataItemValues;

              hardening.forEach((har: any) => {
                if (
                  har.metaDataItemId == 1000013206 ||
                  har.metaDataItemId == 1000013207 ||
                  har.metaDataItemId == 1000013208
                ) {
                  har.normal = "正常";
                  har.normal2 =
                    har.metaDataItemId == 1000013108 ? "水满" : "低水位";
                  har.bgc = har.value == 0 ? "#01FD66" : "#fff";
                  har.bgc2 = har.value == 0 ? "#fff" : "#01FD66";
                  har.show = true;
                  har.widths = 0;
                }
                hardening2.push(har);
              });

              data.hardening = JSON.parse(JSON.stringify(hardening2));
            }
          });
        }

        // 抛丸喷油区域
        if (item.id == "1000014") {
          item.dataValues.forEach((it: any) => {
            if (it.groupInfo.id == 10000142) {
              data.MotorData.series[0].data = [];
              data.MotorData.xAxis.data = [];
              it.dataItemValues.forEach((ItemValue: any) => {
                data.MotorData.series[0].data.push(ItemValue.value);
                data.MotorData.xAxis.data.push(ItemValue.title);
              });
            }
            if (it.groupInfo.id == 10000141) {
              data.ShotBlasting = it.dataItemValues;
              data.waterList = [];
              it.dataItemValues.forEach((ItemValue: any) => {
                if (ItemValue.metaDataItemId == 1000014110) {
                  ItemValue.bgc = "red";
                  data.waterList.push(ItemValue);
                }
                if (ItemValue.metaDataItemId == 1000014111) {
                  ItemValue.bgc = "skyblue";
                  data.waterList.push(ItemValue);
                }
                if (ItemValue.metaDataItemId == 1000014112) {
                  ItemValue.bgc = "skyblue";
                  data.waterList.push(ItemValue);
                }
                if (ItemValue.metaDataItemId == 1000014113) {
                  ItemValue.bgc = "#FFFF00";
                  data.waterList.push(ItemValue);
                }
              });
            }
          });
        }

        // 底部机器人 状态流程 区域
        if (item.id == "1000017") {
          item.dataValues.forEach((it: any) => {
            if (it.groupInfo.id == 10000171) {
              data.StateFlow = it.dataItemValues;
            }
          });

          data.Time = new Date().getTime();
        }
      });
      getYield();
      getLine();
      getMotor();
      //   },
      //   (err: any) => {
      //     ElMessage.error(err.msg);
      //   }
      // );
    };

    const LnfoAlert = () => {
      Api.commonPost(
        "dap",
        "LnfoAlert",
        {
          companyId: store.state.companyId,
          projectId: store.state.projectId,
        },
        (res: any) => {
          data.Arr = [];
          res.data.alertInfoList.forEach((item: any, i: any) => {
            if (i <= 5) {
              data.Arr.push(item);
            }
          });
          data.tableObj = res.data;
        },
        (err: any) => {
          ElMessage.error(err.msg);
        }
      );
    };

    const line = <any>ref();
    const Yields = <any>ref();
    const motor = <any>ref();
    const getLine = () => {
      publicEcharts(line._value, data.lineData);
    };
    const getYield = () => {
      publicEcharts(Yields._value, data.YieldData);
    };
    const getMotor = () => {
      publicEcharts(motor._value, data.MotorData);
    };

    const publicEcharts = (Dom: any, Data: any) => {
      let myChart = echarts.init(Dom);
      myChart.setOption(Data);

      window.addEventListener("resize", function () {
        myChart.resize();
      });
    };

    // 触发弹框
    const MyMyDialog = <any>ref();
    const getDialog = () => {
      let item = {
        name: "异常报警",
        widths: "45%",
      };
      MyMyDialog.value.getShow(item);
    };

    return {
      ...toRefs(data),
      line,
      Yields,
      motor,
      MyMyDialog,
      getDialog,
      getClassName,
      getbyPage,
      LnfoAlert,
    };
  },
});
